<template>
    <div class="main reestr">
        <section class="main-width">
            <div class="row">
                <div class="col-10 offset-1">
                    <div class="reestr-main">
                        <h2> {{$t('search.reestr_search')}} </h2>

                        <form class="reestr-form" @submit.prevent="searchHandler">
                            <div class="form-block-one">
                                <input type="text" class="form-control" v-model="filter.name" :placeholder="$t('subjects.name')">
                                <button type="submit" class="btn"> {{$t('default.search')}} </button>
                            </div>
                            <div class="form-block-two">
                                <div class="radio-group ">
                                    <input type="radio" id="one" value="1"  v-model="filter.partsTypeId">
                                    <label for="one">{{$t('organization.ul_info')}}</label>

                                    <input type="radio" id="two" value="2" v-model="filter.partsTypeId">
                                    <label for="two">{{$t('organization.fl_info')}}</label>
                                </div>
                                <v-select 
                                    class="style-chooser"
                                    v-if="purchaseAreas"
                                    label="name"
                                    multiple
                                    v-model="filter.systemId"
                                    :reduce="prefix => prefix.id"
                                    :placeholder="$t('search.system')"
                                    :options="purchaseAreas">
                                </v-select> 
                            </div>
                            <div class="form-block-three">
                                <div style="display: block;">
                                    
                                    <label for="check2" class="mr-4">
                                        <input type="checkbox"  v-model="filter.isUnscrupulous" id="check2"> <i18n path="subjects.isUnscrupulous"> </i18n> 
                                    </label>
                                    <br/>
                                    <label for="check1">
                                        <input type="checkbox"  v-model="filter.isCt" id="check1"> <i18n path="subjects.isCt"> </i18n> 
                                    </label>
                                    <br/>
                                    <label for="checkRqs">
                                        <input type="checkbox"  v-model="filter.rqs" id="checkRqs"> <i18n path="subjects.register_of_qualified_suppliers"> </i18n> 
                                    </label>
                                    <br/>
                                </div>
                                <div>
                                    <button type="reset" class="btn btn__reset" @click="resetHandler"> {{$t('search.reset')}} </button>
                                </div>
                            </div>
                        </form>
                        <div class="table-area">
                            <div class="paginator-area">
                                <div class="paginator">
                                    <paginate
                                        v-model="currentPage"
                                        :page-count="pageCount"
                                        :click-handler="changePage"
                                        :prev-text="'&laquo;'"
                                        :next-text="'&raquo;'"
                                        :container-class="'pagination'"
                                        :page-class="'page-item'"
                                        :page-link-class="'page-link'"
                                        :prev-class="'page-item'"
                                        :prev-link-class="'page-link'"
                                        :next-class="'page-item'"
                                        :next-link-class="'page-link'"
                                        >
                                    </paginate>
                                </div>
                            </div>
                            <div>
                                <beat-loader 
                                    v-if="loading" 
                                    class="loader-center" 
                                    :color="'#553DB4'" 
                                    :loading="loading" 
                                    :size="20" 
                                    :sizeUnit="'px'"
                                /> 
                                <shops-table 
                                    v-else 
                                    :items="this.content.items"
                                />
                                <div v-if="this.content.items == 0" class="text-center not-found ">
                                    {{ $t("message.nothing_found") }}
                                    <button type="reset" @click="resetHandler" class="btn btn__reset ml-2"> {{$t('search.reset')}} </button>
                                </div>
                            </div>
                            <div class="paginator-area">
                                <div class="paginator">
                                    <paginate
                                    v-model="currentPage"
                                        :page-count="pageCount"
                                        :click-handler="changePage"
                                        :prev-text="'&laquo;'"
                                        :next-text="'&raquo;'"
                                        :container-class="'pagination'"
                                        :page-class="'page-item'"
                                        :page-link-class="'page-link'"
                                        :prev-class="'page-item'"
                                        :prev-link-class="'page-link'"
                                        :next-class="'page-item'"
                                        :next-link-class="'page-link'"
                                        >
                                    </paginate>
                                </div>
                                <div class="quantity__text">
                                    <p>{{$t('plan.row_cnt')}}: {{this.content.totalElements | sum}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>

import shopsTable from '@/components/reestrPage/shopsTable'

import { mixin as clickaway } from "vue-clickaway"



    export default {
        name: 'reestr',
        mixins: [clickaway],
        components: {
            shopsTable, 
        },
        data: () => ({
            content: {
                totalElements: null,
                items: null
            },
            selected: null,
            loading: true,
            showSearch: false,
            size: 15,
            currentPage: 1,
            allTru: null,
            filter: {
                name: null,
                isCt: false,
                isUnscrupulous: false,
                rqs: false,
                partsTypeId: "1",
                systemId: null,
            },
            dSystemId: null,
            purchaseAreas: null,
            tradeMethod: null,
            planStatus: null

        }),
        mounted() {
            this.getPlans();
            this.getPurchaseArea()
        },
        methods: {
            getPurchaseArea() {
                let params = {
                    entity: 'DictionaryItem',
                    filter: {'dictionaryId.code': "System"},
                    page: 0,
                    length: 50,
                }
                this.$store.dispatch('GET_PURCHASE_AREA', params)
                    .then( res => {
                        this.purchaseAreas = res.content
                    })
            },
            searchHandler(){
                this.getPlans()
                this.filter.name = null
            },
            resetHandler() {
                for (let val in this.filter) {

                    this.filter[val] = null
                }
                this.allTru = null
                this.currentPage = 1
                this.$store.commit('RESET_CLICKED_CITY')
                this.getPlans()
            },
            showFilter() {
                this.showSearch = !this.showSearch
            },
            changePage(currentPage) {
                this.currentPage = currentPage
                this.getPlans()
            },
            getPlans() {
                this.loading = true;
                let params = {
                    page: this.currentPage - 1,
                    entity: 'Subject',
                    length: 15,
                    filter: {}
                }

                if (this.selected) {
                    params.sort = [this.selected]
                }

                if ( this.filter ) {
                    for (const key in this.filter) {
                        if(this.filter[key]) {
                            params.filter[key] = this.filter[key]
                        }
                    }
                }

                if(this.filter.partsTypeId === "1") {
                    params.filter.partsTypeId = [1]
                } else if (this.filter.partsTypeId === "2") {
                    params.filter.partsTypeId = [2]
                } else {
                    params.filter.partsTypeId = null
                }

                 this.$store.dispatch('GET_LIST', params) 
                    .then((res) => {
                        this.content.items = res.data.content
                        this.content.totalElements = res.data.totalElements
                        this.loading = false
                    })
                    .catch(() => {
                        this.$toast.error('Ошибка запроса')
                        this.content.items = 0
                        this.loading = false
                    })
            },
        },
        computed: {
            pageCount() {
                let l = this.content.totalElements
                let s = this.size
                return Math.ceil(l/s)
            },
            length_() {
                if (this.content.items) {
                    return true
                }

                return false
            }

        }
    }
</script>

<style lang="scss" scoped>
.style-chooser {
    width: 100%;
}
.form-block-one .btn {
    &:hover {
        color: #fff;
    }
}
</style>