<template>
    <div class="shops-modal">
        <modal
            name="shops-modal"
            :width="1200"
            :height="600"
            :adaptive="true" 
        >
            <div class="text__area" v-if="tender && info">
                <h4 class="text-center" >{{translatel(tender, 'name')}} </h4>

                <p class="close" @click="hide"><i class="fas fa-times"></i></p>

                <div class="shops-modal__content">

                    <p> {{ $t("subjects.card") }} </p>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.bin") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.biniin }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.name_kz") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.namekz }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.year") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.regYear }} </div>
                    </div>

                    <p> {{ $t("subjects.contact_info") }} </p>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.juraddress") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.juraddress }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.hkatojuraddress") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.hkatojuraddress }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.factaddress") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.factaddress }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.hkatofactaddress") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.hkatofactaddress }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("organization.orgPhoneNumber") }} </div>
                        <div class="col-sm-7 col-12"> {{info.phone}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("market.email") }} </div>
                        <div class="col-sm-7 col-12"> {{info.email}} </div>
                    </div>

                    <p> {{ $t("subjects.company") }} </p>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.registeredAt") }} </div>
                        <div class="col-sm-7 col-12"> {{info.registeredAt}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.unscrupulousSupplier") }} </div>
                        <div class="col-sm-7 col-12"> {{ info.unscrupulous ? $t("subjects.yes") : $t("subjects.no") }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.certified_producers") }} </div>
                        <div class="col-sm-7 col-12"> {{info.ct ? $t("subjects.yes") : $t("subjects.no") }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.register_of_qualified_suppliers") }} </div>
                        <div class="col-sm-7 col-12"> {{tender.rqs ? $t("subjects.yes") : $t("subjects.no") }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.invalidOrganization") }} </div>
                        <div class="col-sm-7 col-12"> {{ tender.is_ooi ? $t("subjects.yes") : $t("subjects.no") }} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.isResident") }} </div>
                        <div class="col-sm-7 col-12"> {{info.isresident ? $t("subjects.yes") : $t("subjects.no") }} </div>
                    </div>

                    <p> {{ $t("subjects.okedList") }} </p>
                    <div class="row" v-for="item in info.subjectOkedList" v-bind:key="item.oked">
                        <div class="col-sm-5 col-12"> {{ item.oked }} </div>
                        <div class="col-sm-7 col-12"> {{item.name}} </div>
                    </div>

                    <p> {{ $t("subjects.head") }} </p>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("subjects.fio") }} </div>
                        <div class="col-sm-7 col-12"> {{info.directorfio}} </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-12"> {{ $t("appeal.iin") }} </div>
                        <div class="col-sm-7 col-12"> {{info.directoriin}} </div>
                    </div>
                </div>

                

                <button class="btn btn-close" @click="hide">Закрыть</button>              
            </div>
        </modal>
    </div>
</template>

<script>
    export default {
        props: {
            tender: {
                type: Object,
                required: true
            },
            info: {
                type: Object,
                required: true
            }
        },
        name: 'shops-modal',
        data: () => ({
        }),
        methods: {
            hide() {
                this.$modal.hide('shops-modal')
            },
            translatel(i, fieldname) {
                let field, alternative;
                switch(this.$i18n.locale) {
                    case 'kz':
                        field = fieldname + 'kz';
                        alternative = fieldname + 'ru';
                        break;
                    default:
                        field = fieldname + 'ru';
                        alternative = fieldname + 'kz';
                }

                return i[field] || i[alternative];
            },
            translate(i, fieldname) {
                let field, alternative;
                switch(this.$i18n.locale) {
                    case 'kz':
                        field = fieldname + 'Kz';
                        alternative = fieldname + 'Ru';
                        break;
                    default:
                        field = fieldname + 'Ru';
                        alternative = fieldname + 'Kz';
                }

                return i[field] || i[alternative];
            }
        },
        computed: {
            planLink() {
                const url = this.info.system.contractUrl
                const newUrl = url.replace('@@@', `${this.info.externalId}`)
                return newUrl
            }
        },
    }
</script>   

<style lang="scss" scoped>
.shops-modal {
    &__content{
        margin: 20px 0;
        // border: 1px solid #ccc;
        border-radius: 5px;
        .row {
            margin: 0;
            border: 1px solid rgb(231, 231, 231);
            // border-bottom: none;
            // background: rgba(197, 197, 197, 0.5);
            font-size: 14px;
            &:first-child{
                a {
                    display: inline;
                    color: #553DB4;
                }
            }
            &:last-child{
                border-bottom: 1px solid rgb(231, 231, 231);
            }
            .col-12 {
                padding: 12px 15px;
                @media(max-width: 575px) {
                    text-align: center;
                }
            }
            .col-sm-5 {
                border-right: 1px solid #ccc;
                text-align: right;
                @media(max-width: 575px) {
                    text-align: center;
                    border-right: none;
                }
            }
            &:nth-child(odd) {
                background: rgb(241, 241, 241);
            }
        }
    }
}
.text__area {
    padding: 50px;
}
.btn-close {
    background: #FFAD00;
    display: block;
    color: #fff;
    width: 300px;
    margin: 0 auto;
}
h4 {
    font-size: 20px;
}
p {
    margin: 30px 0 10px 0;
    text-align: center;
}
table {
    font-size: 14px !important;
}
</style>