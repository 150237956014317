<template>
    <div class="table-responsive-lg">
        <table class="table table-hover table-borderless">
            <thead class="thead-bg">
                <tr>
                    <th scope="col">{{$t('search.name')}}</th>
                    <th scope="col">{{$t('search.area')}}</th>
                    <th scope="col"> БИН/ИИН </th>
                </tr>
            </thead>
            <tbody class="nd">
                <tr
                    v-for="item in items"
                    :key="item.id"
                >
                    <th scope="row">
                        <a href="#" @click.prevent="show(item)"> {{ translatel(item, 'name') }} </a>
                    </th>
                    <td><a href="#" @click.prevent="show(item)"> {{ translate(item, 'systemName') }} </a></td>
                    <td> {{ item.biniin }} </td>
                </tr>
                <shops-modal v-if="customerInfo" :tender="clickedRow" :info="customerInfo"/>
            </tbody>
        </table>
    </div>
</template>

<script>

import shopsModal from '../reestrPage/shopsModal'

export default {
    props: ['items',],
    name: 'shops-table',
    components: {
        shopsModal
    },
    data: () => ({
        clickedRow: null,
        customerInfo: null
    }),
    mounted() {
    },
    methods: {
        show(item) {
            this.clickedRow = item
            this.$modal.show('shops-modal')
            this.get_plans(item.biniin)
        },
        get_plans(id){

            let params = {
                entity: "Subject",
                uuid: id
            }

            this.$store.dispatch('CLICKED_LIST', params)
                .then(res => {
                    this.customerInfo = res.data
                })
                .catch(() => {
                    this.clickedRow = null
                    this.customerInfo = null
                    this.$toast.error('Внутренняя ошибка сервера (код 500)')
                })
        },
        translatel(i, fieldname) {
            let field, alternative;
            switch(this.$i18n.locale) {
                case 'kz':
                    field = fieldname + 'kz';
                    alternative = fieldname + 'ru';
                    break;
                default:
                    field = fieldname + 'ru';
                    alternative = fieldname + 'kz';
            }

            return i[field] || i[alternative];
        },
        translate(i, fieldname) {
            let field, alternative;
            switch(this.$i18n.locale) {
                case 'kz':
                    field = fieldname + 'Kz';
                    alternative = fieldname + 'Ru';
                    break;
                default:
                    field = fieldname + 'Ru';
                    alternative = fieldname + 'Kz';
            }

            return i[field] || i[alternative];
        }
    },
}
</script>

<style lang="scss" scoped>
table {
    font-size: 14px;
}
table tbody tr th{
    cursor: pointer;
}
.table .thead-bg {
    background: #F0F1F7;
}
</style>